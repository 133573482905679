import React, { Fragment, useState, useEffect } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import DeleteIcon from "@material-ui/icons/Delete";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import AddIcon from "@mui/icons-material/Add";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@material-ui/icons/Edit";
import petitionPatch from "../../services/petitionPatch";
import petitionGet from "../../services/petitionGet";
import petitionPost from "../../services/petitionPost";
import petitionDelete from "../../services/petitionDelete";
import Loading from "../../jsx/utils/Loading";
import getDate from "../../jsx/utils/getDate";
import ButtonStyled from "../../jsx/utils/ButtonStyled";
import ModalDelete from "./ModalUtility/ModalDelete";
import { toast } from "react-toastify";
import Select from "react-select";
import ModalError from "./ModalUtility/ModalError";
import Switch from "react-switch";
import "./AgentsDetails.css";

const AgentsDetails = ({
  agentDetails,
  setAgentDetails,
  info,
  closeMenu,
  ratesGroups,
  formatData,
}) => {
  const [editField, setEditField] = useState(null);
  const [editIndividual, setEditIndividual] = useState(null);
  const [loadingIndividual, setLoadingIndividual] = useState(false);
  const [loadingContract, setLoadingContract] = useState(false);
  const [loading, setLoading] = useState(true);
  const [contracts, setContracts] = useState([]);
  const [showAddContract, setShowAddContract] = useState(false);
  const [showDeleteContract, setShowDeleteContract] = useState(null);
  const [showEditContract, setShowEditContract] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [form, setForm] = useState(null);
  const [carriers, setCarriers] = useState([]);
  const [showAddAppointment, setShowAddAppointment] = useState(false);
  const [showEdittAppointment, setShowEditAppointment] = useState(null);
  const [loadingAppointment, setloadingAppointment] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [infoDelete, setInfoDelete] = useState(false);
  const [edited, setEdited] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [formOverrides, setFormOverrides] = useState({
    override_advanced_commissions: agentDetails?.override_advanced_commissions || false,
    override_advanced_commissions_months: agentDetails?.override_advanced_commissions_months || "",
  })

  
  const Offsymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 10,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        <p style={{ marginTop: "1.5em" }}>No</p>
      </div>
    );
  };

  const OnSymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 10,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        <p style={{ marginTop: "1.5em" }}>Yes</p>
      </div>
    );
  };

  const sendToastSuccess = () => {
    toast.success("✔️ Success !!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const sendToastError = () => {
    toast.error("Error", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleChangeIndividual = (e) => {
    if (editIndividual) {
      setEditIndividual({ ...editIndividual, [e.target.name]: e.target.value });
    } else {
      setEditIndividual({ [e.target.name]: e.target.value });
    }
  };

  const handleChangeOverrrideMonts = (e) => {
    const { value } = e.target

    if (/^\d*$/.test(value)) {
      setFormOverrides({ ...formOverrides, override_advanced_commissions_months: value });
    }
  }

  const handleChange = (e) => {
    if (form) {
      setForm({ ...form, [e.target.name]: e.target.value });
    } else {
      setForm({ [e.target.name]: e.target.value });
    }
    setEdited(true);
  };

  const backFunction = () => {
    setForm(null);
    setEdited(false);
    setShowAddContract(false);
    setShowDeleteContract(null);
    setShowEditContract(null);
    setShowAddAppointment(false);
  };

  const activeAddAppointment = () => {
    setShowAddAppointment(true);
  };

  const activeEditAppointment = (e) => {
    setShowAddAppointment(true);
    if (!showEditContract) {
      setForm({
        carrier_id: e.carrier_id,
        carrier_name: carriers.find((element) => element.pk === e.carrier_id)
          .name,
        agent_id_number: e.agent_id_number,
        // start_date: e.start_date.split(" ")[0],
        // end_date: e.end_date.split(" ")[0],
        pk: e.pk,
      });
      setShowEditAppointment(e);
    }
  };

  const activeDeleteAppointment = (e) => {
    petitionDelete("appointments", { appoiment_id: e.pk })
      .then(() => {
        getAppointments();
        sendToastSuccess();
      })
      .catch((err) => {
        sendToastError();
        console.log(err);
      });
  };

  const addAppointment = () => {
    setloadingAppointment(true);
    let senData = {
      agent_id: agentDetails.pk,
      carrier_id: parseInt(form.carrier_id),
      agent_id_number: form.agent_id_number,
      start_date: `${form.start_date} 20:54:51.673832+00:00`,
      end_date: `${form.end_date} 20:54:51.673832+00:00`,
    };

    if (form.pk) {
      petitionPatch("appointments", { appointment_id: form.pk, data: senData })
        .then(({ data: result }) => {
          getAppointments();
          backFunction();
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            setloadingAppointment(false);
            setErrorMessage(error.response.data.error);
            setModalError(true);
          } else {
            console.log(error);
          }
        });
    } else {
      petitionPost("appointments", senData)
        .then(({ data: result }) => {
          getAppointments();
          backFunction();
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            setloadingAppointment(false);
            setErrorMessage(error.response.data.error);
            setModalError(true);
          } else {
            console.log(error);
          }
        });
    }
  };

  const closeModal = () => {
    setModalError(false);
    setErrorMessage(null);
  };

  const activeAddContract = () => {
    if (!showDeleteContract) setShowAddContract(true);
  };

  const activeDeleteContract = (e) => {
    setShowDeleteContract(e);
  };

  const activeEditContract = (e) => {
    if (!showEditContract) {
      setForm({
        rate_group_id: e.rate_group_id,
        rateGroupName: ratesGroups.find((rate) => rate.pk === e.rate_group_id)
          .name,
      });
      setShowEditContract(e);
    }
  };

  const deleteContract = () => {
    setLoadingContract(true);
    petitionDelete("contracts", showDeleteContract.pk)
      .then(({ data: result }) => {
        getContracts(true);
      })
      .catch((error) => console.log(error));
  };

  const addContract = () => {
    setLoadingContract(true);
    let senData = {
      agent_id: agentDetails.pk,
      rate_group_id: parseInt(form.rate_group_id),
    };

    petitionPost("contracts", senData)
      .then(({ data: result }) => {
        getContracts(true);
      })
      .catch((error) => console.log(error));
  };

  const editContract = (contract) => {
    setLoadingContract(true);
    let data = {
      rate_group_id: parseInt(form?.rate_group_id),
    };
    petitionPatch("contracts", {
      data,
      contract_id: contract?.pk,
    })
      .then(({ data: result }) => {
        getContracts(true);
      })
      .catch((error) => console.log(error));
  };

  const petitionIndividual = () => {
    if (editIndividual) {
      setLoadingIndividual(true);
      petitionPatch("agents", {
        agent_id: agentDetails.pk,
        data: editIndividual,
      })
        .then(({ data: result }) => {
          result.result.created_at = formatData(result.result.created_at)
          result.result.updated_at = formatData(result.result.updated_at)
          setAgentDetails(result.result);
          setLoadingIndividual(false);
          setEditIndividual(null);
          setEditField(null);
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            setModalError(true);
            setErrorMessage(error.response.data.error);
            setLoadingIndividual(false);
          } else {
            console.log(error);
          }
        });
    }
  };

  const petitionOverrides = () => {
    if (formOverrides.override_advanced_commissions && !formOverrides.override_advanced_commissions_months) {
      setModalError(true)
      const message = {
        error_message: "When activating the Override Advanced Commissions switch, you must fill in the Override Advanced Commissions Months field. If you do not want to fill it, please deactivate the switch."
      }
      setErrorMessage(message)
    } else {
      let data = { override_advanced_commissions: formOverrides.override_advanced_commissions }
      
      if (formOverrides.override_advanced_commissions) {
        data.override_advanced_commissions_months = formOverrides.override_advanced_commissions_months
      }

      setLoadingIndividual(true);
      petitionPatch("agents", {
        agent_id: agentDetails.pk,
        data,
      })
        .then(({ data: result }) => {
          result.result.created_at = formatData(result.result.created_at)
          result.result.updated_at = formatData(result.result.updated_at)
          setAgentDetails(result.result);
          setLoadingIndividual(false);
          setEditIndividual(null);
          setEditField(null);
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            setModalError(true);
            setErrorMessage(error.response.data.error);
            setLoadingIndividual(false);
          } else {
            console.log(error);
          }
        });
    }
  }

  const getCarriers = () => {
    petitionGet("carriers")
      .then(({ data: result }) => {
        result.result.map((carrier) => {
          carrier.label = carrier.name;
          carrier.value = carrier.pk;
        });
        setCarriers(result.result);
      })
      .catch((error) => console.log(error));
  };

  const getContracts = (noLoading) => {
    if (!noLoading) setLoading(true);
    petitionGet("contracts", { parameter: `?agent_id=${agentDetails.pk}` })
      .then(({ data: result }) => {
        setContracts(result.result);
        setLoading(false);
        setLoadingContract(false);
        backFunction();
      })
      .catch((error) => console.log(error));
  };

  const getAppointments = () => {
    petitionGet("appointments", { parameter: `?agent_id=${agentDetails.pk}` })
      .then(({ data: result }) => {
        setAppointments(result.result);
        setloadingAppointment(false);
      })
      .catch((error) => console.log(error));
  };

  const petitions = () => {
    Promise.all([getCarriers(), getAppointments(), getContracts()])
      .then((result) => {})
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    setAgentDetails({
      ...agentDetails,
      state: agentDetails?.state || "",
      address2: agentDetails?.address2 || "",
      address: agentDetails?.address || "",
      zip: agentDetails?.zip || "",
      city: agentDetails?.city || "",
    })
    petitions();
  }, [info]);

  const deleteFunction = () => {
    activeDeleteAppointment(infoDelete);
    setInfoDelete(false);
    setModalDelete(false);
  };

  const rateGroupName = (pk) => {
    let dataFind = ratesGroups.find((element) => element.pk === pk);
    return dataFind.name;
  };

  return (
    <Fragment>
      <ModalDelete
        openModal={modalDelete}
        modalActions={deleteFunction}
        closeModal={() => {
          setInfoDelete(false);
          setModalDelete(false);
        }}
      />

      {modalError && (
        <ModalError
          modal={modalError}
          closeModal={closeModal}
          errorMessage={errorMessage}
        />
      )}

      {loading ? (
        <div
          style={{ width: "100%", height: "100%" }}
          className="d-flex justify-content-center align-items-center"
        >
          <Loading />
        </div>
      ) : (
        <>
          <div
            style={{
              marginTop: "20px",
              borderBottom: "1px solid rgba(0, 0, 0, 0.07)",
              paddingBottom: "2.5rem",
            }}
            className="d-flex justify-content-between align-items-center"
          >
            <h4 style={{ marginLeft: "20px", marginTop: "10px" }}>
              Agent Details
            </h4>
            <CloseIcon
              style={{ color: "red", marginRight: "1.5rem" }}
              className="icons-hover"
              onClick={() => closeMenu()}
            />
          </div>
          <Row
            style={{
              border: "1px solid rgba(0, 0, 0, 0.07)",
              margin: "2.5rem 20px 0 20px",
            }}
          >
            <Col
              style={{
                padding: "20px 20px 2rem 10px",
                borderRight: "1px solid rgba(0, 0, 0, 0.07)",
              }}
              xs={8}
            >
              {editField === "name" ? (
                <div className="d-flex align-items-center mb-2">
                  <div
                    style={{ marginRight: "10px" }}
                    className="d-flex flex-column"
                  >
                    <div style={{ marginBottom: "0.5rem" }}>
                      <label style={{ marginBottom: "0" }}>First Name</label>
                      <input
                        style={{ marginBottom: "0.1rem" }}
                        className="form-control"
                        defaultValue={agentDetails.first_name || ""}
                        placeholder="First Name"
                        type="text"
                        onChange={handleChangeIndividual}
                        name="first_name"
                      />
                    </div>
                    <div>
                      <label style={{ marginBottom: "0" }}>Last Name</label>
                      <input
                        style={{ marginBottom: "0.1rem" }}
                        className="form-control"
                        defaultValue={agentDetails.last_name || ""}
                        placeholder="Last Name"
                        type="text"
                        onChange={handleChangeIndividual}
                        name="last_name"
                      />
                    </div>
                  </div>
                  <div>
                    {loadingIndividual ? (
                      <Spinner
                        animation="grow"
                        style={{
                          height: "2vh",
                          width: "2vh",
                        }}
                      />
                    ) : (
                      <CheckBoxIcon
                        onClick={() => petitionIndividual()}
                        className="icons-hover"
                      />
                    )}

                    <CloseIcon
                      className="icons-hover"
                      onClick={() => {
                        if (!loadingIndividual) {
                          setEditField(null);
                          setEditIndividual(null);
                          setLoadingIndividual(false);
                        }
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className="container-edit-field">
                  <h4 style={{ marginBottom: "0.1rem", fontSize: "1rem" }}>
                    {agentDetails.first_name} {agentDetails.last_name || ""}
                  </h4>
                  <div className="edit-icon-contact-details">
                    <EditIcon
                      style={{
                        width: "15px",
                        height: "15px ",
                      }}
                      className="icons-hover"
                      onClick={() => setEditField("name")}
                    />
                  </div>
                </div>
              )}

              {editField === "email" ? (
                <div className="d-flex align-items-center mb-2 mt-2">
                  <div
                    style={{ marginRight: "10px" }}
                    className="d-flex flex-column"
                  >
                    <label style={{ marginBottom: "0" }}>Email</label>
                    <input
                      style={{ marginBottom: "0.1rem" }}
                      className="form-control"
                      defaultValue={agentDetails.email || ""}
                      placeholder="Email"
                      type="text"
                      onChange={handleChangeIndividual}
                      name="email"
                    />
                  </div>
                  <div>
                    {loadingIndividual ? (
                      <Spinner
                        animation="grow"
                        style={{
                          height: "2vh",
                          width: "2vh",
                        }}
                      />
                    ) : (
                      <CheckBoxIcon
                        onClick={() => petitionIndividual()}
                        className="icons-hover"
                      />
                    )}

                    <CloseIcon
                      className="icons-hover"
                      onClick={() => {
                        if (!loadingIndividual) {
                          setEditField(null);
                          setEditIndividual(null);
                          setLoadingIndividual(false);
                        }
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className="container-edit-field">
                  <p style={{ marginBottom: "0", whiteSpace: "nowrap" }}>
                    {<MailOutlineIcon />}{" "}
                    <span style={{ color: "#6e6e6e" }}>
                      {agentDetails.email || ""}
                    </span>
                  </p>
                  <div className="edit-icon-contact-details">
                    <EditIcon
                      style={{
                        width: "15px",
                        height: "15px ",
                      }}
                      className="icons-hover"
                      onClick={() => setEditField("email")}
                    />
                  </div>
                </div>
              )}
            </Col>
            <Col xs={4} style={{ padding: "20px 20px 2rem 10px" }}>
              <p
                style={{
                  color: "#6e6e6e",
                  fontSize: "12px",
                  whiteSpace: "nowrap",
                  marginBottom: "0",
                }}
              >
                Balance
              </p>
              <p style={{ marginBottom: "0" }}>
                <strong>{`$${agentDetails.balance.toFixed(2)}`}</strong>
              </p>
            </Col>
          </Row>
          <Row
            style={{
              border: "1px solid rgba(0, 0, 0, 0.07)",
              margin: "2rem 20px 0 20px",
            }}
          >
            <Col
              style={{
                borderRight: "1px solid rgba(0, 0, 0, 0.07)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                paddingLeft: "10px",
              }}
            >
              <div
                style={{
                  height: "1.5rem",
                  width: "5rem",
                  backgroundColor: editField === "address" ? "transparent" : "",
                }}
                className="container-edit-field"
              >
                <p
                  style={{
                    color: "#6e6e6e",
                    fontSize: "12px",
                    marginLeft: "0",
                  }}
                >
                  Address
                </p>
                {editField !== "address" ? (
                  <div className="edit-icon-contact-details">
                    <EditIcon
                      style={{
                        width: "15px",
                        height: "15px ",
                      }}
                      className="icons-hover"
                      onClick={() => setEditField("address")}
                    />
                  </div>
                ) : (
                  <div
                    style={{ marginLeft: "4px" }}
                    className="d-flex align-items-center"
                  >
                    {loadingIndividual ? (
                      <Spinner
                        animation="grow"
                        style={{
                          height: "2vh",
                          width: "2vh",
                        }}
                      />
                    ) : (
                      <CheckBoxIcon
                        onClick={() => petitionIndividual()}
                        className="icons-hover"
                        style={{ height: "18px", width: "18px" }}
                      />
                    )}

                    <CloseIcon
                      className="icons-hover"
                      onClick={() => {
                        setEditField(null);
                        setEditIndividual(null);
                        setLoadingIndividual(false);
                      }}
                    />
                  </div>
                )}
              </div>

              {editField === "address" ? (
                <>
                  <label style={{ marginBottom: "0", marginTop: "1.5rem" }}>
                    Address
                  </label>
                  <input
                    style={{
                      marginBottom: "15px",
                      marginRight: "20px",
                      width: "70%",
                    }}
                    name="address"
                    className="form-control"
                    type="text"
                    onChange={handleChangeIndividual}
                    defaultValue={agentDetails.address}
                  />

                  <label style={{ marginBottom: "0", marginTop: "1.5rem" }}>
                    Address TWo
                  </label>
                  <input
                    style={{
                      marginBottom: "15px",
                      marginRight: "20px",
                      width: "70%",
                    }}
                    name="address2"
                    className="form-control"
                    type="text"
                    onChange={handleChangeIndividual}
                    defaultValue={agentDetails.address2}
                  />

                  <label style={{ marginBottom: "0" }}>City</label>
                  <input
                    style={{ marginBottom: "15px", width: "70%" }}
                    name="city"
                    className="form-control"
                    type="text"
                    onChange={handleChangeIndividual}
                    defaultValue={agentDetails.city}
                  />

                  <label style={{ marginBottom: "0" }}>State</label>
                  <input
                    style={{ marginBottom: "15px", width: "70%" }}
                    name="state"
                    className="form-control"
                    type="text"
                    onChange={handleChangeIndividual}
                    defaultValue={agentDetails.state}
                  />

                  <label style={{ marginBottom: "0" }}>Zip Code</label>
                  <input
                    style={{ marginBottom: "15px", width: "70%" }}
                    name="zip"
                    className="form-control"
                    type="text"
                    onChange={handleChangeIndividual}
                    defaultValue={agentDetails.zip}
                  />
                </>
              ) : (
                <p style={{ marginBottom: "4px", marginLeft: "0" }}>
                  <strong>
                    {agentDetails.address || ""} {agentDetails.address2 || ""}{" "}
                    {agentDetails.city || ""} {agentDetails.state || ""}{" "}
                    {agentDetails.zipcode || ""}
                  </strong>
                </p>
              )}
            </Col>
            <Col></Col>
          </Row>
          <Row
            style={{
              border: "1px solid rgba(0, 0, 0, 0.07)",
              margin: "2rem 20px 0 20px",
            }}
          >
            <Col
              style={{
                borderRight: "1px solid rgba(0, 0, 0, 0.07)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                paddingLeft: "10px",
              }}
            >
              <div>
                <p
                  style={{
                    color: "#6e6e6e",
                    fontSize: "12px",
                    marginLeft: "0",
                  }}
                >
                  CREATED ON
                </p>
              </div>

              <p style={{ marginBottom: "4px", marginLeft: "0" }}>
                <strong>
                  {agentDetails.created_at}
                </strong>
              </p>
            </Col>
            <Col
              style={{
                borderRight: "1px solid rgba(0, 0, 0, 0.07)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                paddingLeft: "10px",
              }}
            >
              <div>
                <p
                  style={{
                    color: "#6e6e6e",
                    fontSize: "12px",
                    marginLeft: "0",
                  }}
                >
                  UPDATED ON
                </p>
              </div>

              <p style={{ marginBottom: "4px", marginLeft: "0" }}>
                <strong>
                  {agentDetails.updated_at}
                </strong>
              </p>
            </Col>
          </Row>
          <div
            style={{
              margin: "20px 18px 0 18px",
            }}
          >
            <div className="d-flex justify-content-between align-items-center">
              <div style={{ gap: "4px" }} className="d-flex">
                <h4>Appointments</h4>
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    backgroundColor: "rgb(248, 248, 248)",
                    fontSize: "10px",
                    width: "20px",
                    height: "20px",
                    marginTop: "2px",
                  }}
                >
                  {appointments.length}
                </div>
              </div>
              <div
                onClick={activeAddAppointment}
                style={{ cursor: "pointer" }}
                className="d-flex"
              >
                <AddIcon style={{ color: "#fc2e53 " }} />
                <p className="text-danger">Add Appointment</p>
              </div>
            </div>
            {showAddAppointment && (
              <>
                <div
                  style={{ marginBottom: "20px" }}
                  className="d-flex align-items-center justify-content-between"
                >
                  <label>Carrier:</label>
                  <div className="col-8">
                    <Select
                      onChange={(e) => {
                        setForm({ ...form, carrier_id: e.value });
                      }}
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder="Select Option"
                      defaultValue={
                        !form?.carrier_name
                          ? {
                              label: "Select Option",
                              value: "",
                            }
                          : {
                              label: form?.carrier_name,
                              value: form?.carrier_id,
                            }
                      }
                      isSearchable={true}
                      name="carrier_id"
                      options={carriers}
                    />
                  </div>
                </div>
                <div
                  style={{ marginBottom: "20px" }}
                  className="d-flex align-items-center justify-content-between"
                >
                  <label>Agent id number:</label>
                  <div className="col-8">
                    <input
                      className="form-control"
                      name="agent_id_number"
                      onChange={handleChange}
                      value={form?.agent_id_number || ""}
                    />
                  </div>
                </div>

                <div style={{ gap: "10px" }} className="d-flex">
                  <ButtonStyled
                    text="Back"
                    disabled={loadingAppointment}
                    onClickFunction={backFunction}
                  />
                  <ButtonStyled
                    text={form?.pk ? "Edit" : "Add"}
                    loading={loadingAppointment}
                    onClickFunction={addAppointment}
                    disabled={!form?.carrier_id || !form?.agent_id_number}
                  />
                </div>
              </>
            )}

            {!showAddAppointment && (
              <>
                {appointments.map((appointment, i) => (
                  <Row
                    style={{
                      border: "1px solid rgba(0, 0, 0, 0.07)",
                      margin:
                        i === appointments.length - 1 ? "0" : "0 0 20px 0",
                    }}
                    key={i}
                  >
                    <Col
                      style={{
                        borderRight: "1px solid rgba(0, 0, 0, 0.07)",
                        paddingLeft: "10px",
                      }}
                      className="d-flex flex-column justify-content-between"
                    >
                      <label style={{ color: "#6e6e6e" }}>Carrier</label>
                      <strong>{appointment.carrier.name}</strong>
                    </Col>
                    <Col
                      style={{
                        borderRight: "1px solid rgba(0, 0, 0, 0.07)",
                        paddingLeft: "10px",
                      }}
                      className="d-flex flex-column justify-content-between"
                    >
                      <label style={{ color: "#6e6e6e" }}>
                        Agent id Number
                      </label>
                      <strong>{appointment.agent_id_number}</strong>
                    </Col>
                    <Col className="d-flex flex-row-reverse align-items-center">
                      <EditIcon
                        onClick={() => activeEditAppointment(appointment)}
                        className="icons-hover"
                      />
                      <DeleteIcon
                        onClick={() => {
                          setInfoDelete(appointment);
                          setModalDelete(true);
                        }}
                        className="icons-hover"
                      />
                    </Col>
                  </Row>
                ))}
              </>
            )}
          </div>
          <div
            style={{
              margin: "30px 18px 0 18px",
            }}
          >
            <div className="d-flex justify-content-between align-items-center">
              <div style={{ gap: "4px" }} className="d-flex">
                <h4>Agent Contracts</h4>
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    backgroundColor: "rgb(248, 248, 248)",
                    fontSize: "10px",
                    width: "20px",
                    height: "20px",
                    marginTop: "2px",
                  }}
                >
                  {contracts.length}
                </div>
              </div>
              {contracts.length === 0 && (
                <div
                  onClick={activeAddContract}
                  style={{ cursor: "pointer" }}
                  className="d-flex"
                >
                  <AddIcon style={{ color: "#fc2e53 " }} />
                  <p className="text-danger">Add Contract</p>
                </div>
              )}
            </div>
            {showAddContract && !showDeleteContract && (
              <>
                <div
                  style={{ marginBottom: "20px" }}
                  className="d-flex align-items-center justify-content-between"
                >
                  <label>Rate Group:</label>
                  <div className="col-8">
                    <Select
                      onChange={(e) => {
                        setForm({ ...form, rate_group_id: e.value });
                      }}
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder="Select Option"
                      defaultValue={
                        !form?.rate_group_id
                          ? {
                              label: "Select Option",
                              value: "",
                            }
                          : {
                              label: form?.rateGroupName,
                              value: form?.rate_group_id,
                            }
                      }
                      isSearchable={true}
                      name="rate_group_id"
                      options={ratesGroups}
                    />
                  </div>
                </div>
                <div style={{ gap: "10px" }} className="d-flex">
                  <ButtonStyled
                    text="Back"
                    disabled={loadingContract}
                    onClickFunction={backFunction}
                  />
                  <ButtonStyled
                    text="Add"
                    loading={loadingContract}
                    onClickFunction={addContract}
                    disabled={!form?.rate_group_id}
                  />
                </div>
              </>
            )}

            {!showAddContract && showDeleteContract && (
              <>
                <div className="d-flex align-items-center justify-content-center">
                  <p>Are you sure?</p>
                </div>
                <div
                  style={{ gap: "10px" }}
                  className="d-flex align-items-center justify-content-center"
                >
                  <ButtonStyled
                    text="Back"
                    disabled={loadingContract}
                    onClickFunction={backFunction}
                  />
                  <ButtonStyled
                    text="Delete"
                    loading={loadingContract}
                    onClickFunction={deleteContract}
                  />
                </div>
              </>
            )}

            {!showAddContract && !showDeleteContract && (
              <>
                {contracts.map((contract, i) =>
                  contract.pk === showEditContract?.pk ? (
                    <Row
                      key={i}
                      style={{
                        border: "1px solid rgba(0, 0, 0, 0.07)",
                        margin: "0 0 0 0",
                        padding: "20px",
                      }}
                    >
                      <div
                        style={{ marginBottom: "20px" }}
                        className="d-flex align-items-center justify-content-between"
                      >
                        <label>Rate Group:</label>
                        <div className="col-8">
                          <Select
                            onChange={(e) => {
                              setForm({ ...form, rate_group_id: e.value });
                              setEdited(true);
                            }}
                            className="basic-single"
                            classNamePrefix="select"
                            placeholder="Select Option"
                            defaultValue={{
                              label: form?.rateGroupName,
                              value: form?.rate_group_id,
                            }}
                            isSearchable={true}
                            name="rate_group_id"
                            options={ratesGroups}
                          />
                        </div>
                      </div>
                      <div style={{ gap: "10px" }} className="d-flex">
                        <ButtonStyled
                          text="Back"
                          disabled={loadingContract}
                          onClickFunction={backFunction}
                        />
                        <ButtonStyled
                          text="Edit"
                          loading={loadingContract}
                          onClickFunction={() => editContract(showEditContract)}
                          disabled={!edited}
                        />
                      </div>
                    </Row>
                  ) : (
                    <Row
                      key={i}
                      style={{
                        border: "1px solid rgba(0, 0, 0, 0.07)",
                        margin: "0",
                      }}
                    >
                      <Col
                        style={{
                          borderRight: "1px solid rgba(0, 0, 0, 0.07)",
                          paddingLeft: "10px",
                        }}
                        xs={6}
                        className="d-flex flex-column justify-content-between"
                      >
                        <label style={{ color: "#6e6e6e" }}>Rate Group</label>
                        <strong>{rateGroupName(contract.rate_group_id)}</strong>
                      </Col>
                      <Col
                        style={{
                          borderRight: "1px solid rgba(0, 0, 0, 0.07)",
                          paddingLeft: "10px",
                        }}
                        xs={6}
                        className="d-flex flex-row-reverse align-items-center"
                      >
                        <EditIcon
                          onClick={() => activeEditContract(contract)}
                          className="icons-hover"
                        />
                        <DeleteIcon
                          onClick={() => activeDeleteContract(contract)}
                          className="icons-hover"
                        />
                      </Col>
                    </Row>
                  )
                )}
              </>
            )}
          </div>
          <div
            style={{
              margin: "30px 18px 4rem 18px",
            }}
          >
            <div className="d-flex justify-content-between align-items-center">
              <h4>ACH Information</h4>
            </div>
            <Row
              style={{
                border: "1px solid rgba(0, 0, 0, 0.07)",
                margin: "0 0 4rem 0",
              }}
            >
              <Col
                style={{
                  borderRight: "1px solid rgba(0, 0, 0, 0.07)",
                  paddingLeft: "10px",
                }}
                xs={6}
                className="d-flex flex-column justify-content-between"
              >
                <div className="container-edit-field">
                  <label style={{ color: "#6e6e6e" }}>Routing Number</label>
                  {editField !== "ach_routing_number" ? (
                    <div className="edit-icon-contact-details">
                      <EditIcon
                        style={{
                          width: "15px",
                          height: "15px ",
                        }}
                        className="icons-hover"
                        onClick={() => setEditField("ach_routing_number")}
                      />
                    </div>
                  ) : (
                    <div
                      style={{ marginLeft: "4px" }}
                      className="d-flex align-items-center"
                    >
                      {loadingIndividual ? (
                        <Spinner
                          animation="grow"
                          style={{
                            height: "2vh",
                            width: "2vh",
                          }}
                        />
                      ) : (
                        <CheckBoxIcon
                          onClick={() => petitionIndividual()}
                          className="icons-hover"
                          style={{ height: "15px", width: "15px" }}
                        />
                      )}

                      <CloseIcon
                        className="icons-hover"
                        onClick={() => {
                          setEditField(null);
                          setEditIndividual(null);
                          setLoadingIndividual(false);
                        }}
                      />
                    </div>
                  )}
                </div>
                {editField === "ach_routing_number" ? (
                  <div style={{ marginBottom: "10px", marginTop: "10px" }}>
                    <input
                      style={{ marginBottom: "0.1rem" }}
                      className="form-control"
                      defaultValue={agentDetails.ach_routing_number || ""}
                      type="number"
                      onChange={handleChangeIndividual}
                      name="ach_routing_number"
                    />
                  </div>
                ) : (
                  <strong>{agentDetails.ach_routing_number}</strong>
                )}
              </Col>
              <Col
                style={{
                  borderRight: "1px solid rgba(0, 0, 0, 0.07)",
                  paddingLeft: "10px",
                }}
                xs={6}
                className="d-flex flex-column justify-content-between"
              >
                <div className="container-edit-field">
                  <label style={{ color: "#6e6e6e" }}>Account Number</label>
                  {editField !== "ach_account_number" ? (
                    <div className="edit-icon-contact-details">
                      <EditIcon
                        style={{
                          width: "15px",
                          height: "15px ",
                        }}
                        className="icons-hover"
                        onClick={() => setEditField("ach_account_number")}
                      />
                    </div>
                  ) : (
                    <div
                      style={{ marginLeft: "4px" }}
                      className="d-flex align-items-center"
                    >
                      {loadingIndividual ? (
                        <Spinner
                          animation="grow"
                          style={{
                            height: "2vh",
                            width: "2vh",
                          }}
                        />
                      ) : (
                        <CheckBoxIcon
                          onClick={() => petitionIndividual()}
                          className="icons-hover"
                          style={{ height: "15px", width: "15px" }}
                        />
                      )}

                      <CloseIcon
                        className="icons-hover"
                        onClick={() => {
                          setEditField(null);
                          setEditIndividual(null);
                          setLoadingIndividual(false);
                        }}
                      />
                    </div>
                  )}
                </div>
                {editField === "ach_account_number" ? (
                  <div style={{ marginBottom: "10px", marginTop: "10px" }}>
                    <input
                      style={{ marginBottom: "0.1rem" }}
                      className="form-control"
                      defaultValue={agentDetails.ach_account_number || ""}
                      type="number"
                      onChange={handleChangeIndividual}
                      name="ach_account_number"
                    />
                  </div>
                ) : (
                  <strong>{agentDetails.ach_account_number}</strong>
                )}
              </Col>
            </Row>
          </div>

          <div className="itp-agent-details__overrides-info">
            <div className="d-flex align-items-center gap-2">
              <h4>Override Advanced Commissions Information</h4>

              {editField !== "override_advanced_commissions" ? (
                <div className="edit-icon-contact-details">
                  <EditIcon
                    style={{
                      width: "18px",
                      height: "18px ",
                      marginTop: "-0.8rem"
                    }}
                    className="icons-hover"
                    onClick={() => setEditField("override_advanced_commissions")}
                    />
                </div>
              ) : (
                <div
                  style={{ marginLeft: "4px" }}
                  className="d-flex align-items-center"
                >
                  {loadingIndividual ? (
                    <Spinner
                      animation="grow"
                      style={{
                        height: "2vh",
                        width: "2vh",
                      }}
                    />
                  ) : (
                    <CheckBoxIcon
                      onClick={() => petitionOverrides()}
                      className="icons-hover"
                      style={{
                        width: "18px",
                        height: "18px ",
                        marginTop: "-0.8rem"
                      }}
                    />
                  )}

                  <CloseIcon
                    className="icons-hover"
                    style={{
                      width: "18px",
                      height: "18px ",
                      marginTop: "-0.8rem"
                    }}
                    onClick={() => {
                      setEditField(null);
                      setEditIndividual(null);
                      setLoadingIndividual(false);
                    }}
                  />
                </div>
              )}
            </div>

            {editField !== "override_advanced_commissions" ? (
              <>
                <label style={{ color: "#6e6e6e" }}>Override Advanced Commissions: {agentDetails.override_advanced_commissions ? "Enabled" : "Disabled"}</label>

                {agentDetails.override_advanced_commissions && (
                  <label style={{ color: "#6e6e6e" }}>Override Advanced Commissions Months: {agentDetails?.override_advanced_commissions_months}</label>
                )}
              </>
            ) : (
              <>
                <div
                  style={{ marginBottom: "10px" }}
                  className="d-flex align-items-center justify-content-between mt-3"
                >
                  <label>Override Advanced Comissions :</label>
                  <div className="col-8">
                    <Switch
                      uncheckedIcon={<Offsymbol />}
                      className="mr-1"
                      checkedIcon={<OnSymbol />}
                      onColor="#626ed4"
                      onChange={() => setFormOverrides({ ...formOverrides, override_advanced_commissions: !formOverrides.override_advanced_commissions })}
                      checked={formOverrides.override_advanced_commissions}
                    />
                  </div>
                </div>

                {formOverrides.override_advanced_commissions && (
                  <div
                    style={{ marginBottom: "10px" }}
                    className="d-flex align-items-center justify-content-between"
                  >
                    <label>Override Advanced Commissions Months :</label>
                    <div className="col-6">
                      <input
                        type="text"
                        className="form-control"
                        onChange={handleChangeOverrrideMonts}
                        defaultValue={formOverrides.override_advanced_commissions_months}
                      ></input>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}
    </Fragment>
  );
};

export default AgentsDetails;
