import React, { useState, useEffect, useRef } from "react";
import CustomTable from "../../../jsx/utils/CustomTable/CustomTable";
import Loading from "../../../jsx/utils/Loading";
import ButtonStyled from "../../../jsx/utils/ButtonStyled";
import petitionGet from "../../../services/petitionGet";
import petitionPost from "../../../services/petitionPost";
import petitionDelete from "../../../services/petitionDelete";
import petitionPatch from "../../../services/petitionPatch";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ModalAddRateRule from "../ModalUtility/ModalAddRateRule";
import ModalDelete from "../ModalUtility/ModalDelete";
import getQueryString from "../../../jsx/utils/getQueryString";

const CommissionRateRules = ({ back, rateRules, details }) => {
  const itemsPerPage = useRef("10");
  const pagination = useRef({
    offset: 0,
    limit: 10,
  });
  const [loading, setLoading] = useState(true);
  const [loadingAddOrEdit, setLoadingAddOrEdit] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [dataTable, setDataTable] = useState(null);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [info, setInfo] = useState(null);
  const [allResult, setAllResult] = useState(null);
  const [form, setForm] = useState({
    pk: null,
    year: "",
    rate: "",
    age_start: "",
    age_end: "",
    carrier_default_commission: "",
  });

  const activeModalAdd = (option, e) => {
    if (option === "add") {
      setModalAdd(true);
    } else {
      let newForm = {
        ...e,
        rate: parseFloat(e.rate.replaceAll("%", "")),
        carrier_default_commission: e?.carrier_default_commission || "",
      };
      setForm(newForm);
      setModalAdd(true);
    }
  };

  const activeModalDelete = (e) => {
    setInfo(e);
    setModalDelete(true);
  };

  const closeModal = () => {
    setModalAdd(false);
    setModalDelete(false);
    setInfo(null);
    setForm({
      pk: null,
      year: "",
      rate: "",
      age_start: "",
      age_end: "",
      carrier_default_commission: "",
    });
  };

  const reloadData = (deleted) => {
    let parameter = "";
    closeModal();

    if (deleted) {
      let paginationTemp = {
        limit: pagination.current.limit,
        offset: pagination.current.offset - pagination.current.limit,
      };
      parameter = getQueryString(paginationTemp);
      petition(parameter, paginationTemp);
      pagination.current = paginationTemp;
    } else {
      parameter = getQueryString(pagination.current);
      petition(parameter, pagination.current);
    }
  };

  const addOrEditRateRule = () => {
    setLoadingAddOrEdit(true);
    let data = {
      year: form.year.toString(),
      age_start: form.age_start.toString(),
      age_end: form.age_end.toString(),
      rate: Number(parseFloat(form.rate) / 100),
    };

    if (form.carrier_default_commission) {
      data.carrier_default_commission = form.carrier_default_commission
    }

    if (!data.rate) data.rate = null

    if (form.pk) {
      petitionPatch("rate-rules", {
        rate_group_id: rateRules.pk,
        rate_rule_id: form.pk,
        data,
      })
        .then(({ data: result }) => {
          setLoadingAddOrEdit(false);
          reloadData();
        })
        .catch((error) => console.log(error));
    } else {
      petitionPost("rate-rules", { rate_group_id: rateRules.pk, data })
        .then(({ data: result }) => {
          setLoadingAddOrEdit(false);
          reloadData();
        })
        .catch((error) => console.log(error));
    }
  };

  const deleteRateRule = () => {
    setLoadingDelete(true);
    petitionDelete("rate-rules", {
      rate_group_id: rateRules.pk,
      rate_rule_id: info.pk,
    })
      .then(({ data: result }) => {
        setLoadingDelete(false);
        if (allResult.result.length === 1 && parseInt(allResult.offset) !== 0) {
          reloadData(true);
        } else {
          reloadData();
        }
      })
      .catch((error) => console.log(error));
  };

  const formatPercentage = (value) => {
    let strNumber = (value * 100).toString();
    const regex = /^\d+(?:\.\d{0,2})?$/;

    if (!regex.test(strNumber)) {
      if (strNumber.includes(".")) {
        return `${parseFloat(strNumber).toFixed(2)}%`;
      } else {
        return `${strNumber}%`;
      }
    } else {
      return `${parseFloat(strNumber).toFixed(2)}%`;
    }
  };

  const petition = (parameter, paginationTemp, noLoading) => {
    if (!noLoading) setLoading(true);
    petitionGet("rate-rules", { rate_group_id: rateRules.pk, parameter })
      .then(({ data: result }) => {
        setAllResult(result);
        result.result.forEach((element, i) => {
          element.positionTable = (paginationTemp?.offset || 0) + i + 1;
          element.rate = formatPercentage(element.rate);
          element.carrier_default_commission = element?.carrier_default_commission || ""
        });
        setDataTable({
          title: [
            // { title: "#", key: "positionTable" },
            { title: "Year", key: "year" },
            { title: "Rate", key: "rate" },
            { title: "Age Start", key: "age_start" },
            { title: "Age End", key: "age_end" },
            { title: "Age End", key: "age_end" },
            { title: "Fixed commission amount", key: "carrier_default_commission" },
          ],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                name: "Edit",
                icon: <EditIcon />,
                handleClick: (e) => activeModalAdd(false, e),
              },
              {
                name: "Delete",
                icon: <DeleteIcon />,
                handleClick: (e) => activeModalDelete(e),
              },
            ],
          },
          noColumnNumber: true,
          addButton: {
            label: "Add Rate",
            handleClick: () => activeModalAdd("add"),
          },
          itemsPerPage: (e) => activeItemsPerPage(e),
          changePage: (page) => changePage(page),
        });
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const changePage = (page) => {
    let paginationTemp = {
      limit: pagination.current.limit,
      offset: page === 1 ? 0 : (page - 1) * pagination.current.limit,
    };

    let parameter = getQueryString(paginationTemp);
    petition(parameter, paginationTemp);
    pagination.current = paginationTemp;
  };

  const activeItemsPerPage = (data) => {
    let paginationTemp = {
      offset: 0,
      limit: parseInt(data),
    };

    let parameter = getQueryString(paginationTemp);
    petition(parameter, paginationTemp);
    itemsPerPage.current = data;
    pagination.current = paginationTemp;
  };

  useEffect(() => {
    petition(getQueryString(pagination.current), pagination.current);
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <>
      {modalAdd && (
        <ModalAddRateRule
          modal={modalAdd}
          closeModal={closeModal}
          addFunction={addOrEditRateRule}
          loading={loadingAddOrEdit}
          form={form}
          setForm={setForm}
        />
      )}

      {modalDelete && (
        <ModalDelete
          openModal={modalDelete}
          modalActions={deleteRateRule}
          closeModal={closeModal}
          loading={loadingDelete}
        />
      )}

      <div
        style={{ gap: "10px", marginLeft: "24px", marginTop: "10px" }}
        className="d-flex align-items-center"
      >
        <ButtonStyled text="Back" onClickFunction={back} />
        <h4>{details.name}</h4>
      </div>

      {dataTable && (
        <CustomTable
          noClass={true}
          data={dataTable}
          allResult={allResult}
          noOverflow={true}
          pagination={itemsPerPage.current}
        />
      )}
    </>
  );
};

export default CommissionRateRules;
