// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-edit-field {
  display: flex;
}

.container-edit-field:hover {
  background: #efefef;
}

.edit-icon-contact-details {
  visibility: hidden;
}

.container-edit-field:hover > .edit-icon-contact-details {
  visibility: visible;
}

.itp-agent-details__overrides-info {
  display: flex;
  flex-direction: column;
  margin: 30px 18px 4rem;
}

.itp-agent-details__overrides-info:hover .edit-icon-contact-details {
  visibility: visible;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Agents/AgentsDetails.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".container-edit-field {\n  display: flex;\n}\n\n.container-edit-field:hover {\n  background: #efefef;\n}\n\n.edit-icon-contact-details {\n  visibility: hidden;\n}\n\n.container-edit-field:hover > .edit-icon-contact-details {\n  visibility: visible;\n}\n\n.itp-agent-details__overrides-info {\n  display: flex;\n  flex-direction: column;\n  margin: 30px 18px 4rem;\n}\n\n.itp-agent-details__overrides-info:hover .edit-icon-contact-details {\n  visibility: visible;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
