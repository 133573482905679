import React from "react";
import { Modal } from "react-bootstrap";
import Switch from "react-switch";

import ButtonStyled from "../../../jsx/utils/ButtonStyled";
const ModalAdd = ({
  openModal,
  modalActions,
  closeModal,
  contacts,
  sources,
  form,
  setForm,
  stages,
  loading,
  isValidEmail,
  setIsValidEmail,
  validateEmail,
  errorMessage,
}) => {
  const subAgencyItem = JSON.parse(localStorage.getItem("subAgencyDetails"));

  const update = () => {
    modalActions(form);
  };

  const Offsymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 10,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        <p style={{ marginTop: "1.5em" }}>No</p>
      </div>
    );
  };
  const OnSymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 10,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        <p style={{ marginTop: "1.5em" }}>Yes</p>
      </div>
    );
  };

  const handleChange = (e) => {
    setForm({ ...form, email: e.target.value });
    if (!isValidEmail && e.target.value !== "") {
      setIsValidEmail(validateEmail(e.target.value));
    } else if (!isValidEmail && e.target.value === "") {
      setIsValidEmail(true);
    }
  };

  const handleChangeCommissionsMonths = (e) => {
    const { value } = e.target

    if (/^\d*$/.test(value)) {
      setForm({ ...form, override_advanced_commissions_months: value });
    }
  }

  return (
    <Modal className="fade" show={openModal}>
      <Modal.Header>
        <Modal.Title>{form.pk ? "Edit Agent" : "Create Agent"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>First Name:</label>
          <div className="col-8">
            <input
              className="form-control"
              onChange={(e) => setForm({ ...form, first_name: e.target.value })}
              value={form.first_name}
            ></input>
          </div>
        </div>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Last Name:</label>
          <div className="col-8">
            <input
              className="form-control"
              onChange={(e) => setForm({ ...form, last_name: e.target.value })}
              value={form.last_name}
            ></input>
          </div>
        </div>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Email:</label>
          <div className="col-8">
            <input
              className="form-control"
              onChange={handleChange}
              value={form.email}
            ></input>
            {!isValidEmail && (
              <p className="text-danger">Please enter a valid email address.</p>
            )}
          </div>
        </div>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Override Advanced Comissions :</label>
          <div className="col-8">
            <Switch
              uncheckedIcon={<Offsymbol />}
              className="mr-1"
              checkedIcon={<OnSymbol />}
              onColor="#626ed4"
              onChange={() => setForm({ ...form, override_advanced_commissions: !form.override_advanced_commissions })}
              checked={form.override_advanced_commissions}
            />
          </div>
        </div>

        {form.override_advanced_commissions && (
          <div
            style={{ marginBottom: "10px" }}
            className="d-flex align-items-center justify-content-between"
          >
            <label>Override Advanced Commissions Months :</label>
            <div className="col-8">
              <input
                type="text"
                className="form-control"
                onChange={handleChangeCommissionsMonths}
                value={form.override_advanced_commissions_months || ""}
              ></input>
           </div>
          </div>
        )}

        {subAgencyItem && (
          <div
            style={{ marginBottom: "10px" }}
            className="d-flex align-items-center justify-content-between"
          >
            <label>Sub Agency</label>
            <div className="col-8">
              <select
                defaultValue={subAgencyItem.name}
                className="arrow-select"
                name="sub_agency_id"
              >
                <option disabled value={subAgencyItem.name}>
                  {subAgencyItem.name}
                </option>
              </select>
            </div>
          </div>
        )}

        {errorMessage && (
          <>
            {errorMessage?.error_message != null &&
            errorMessage.error_message != "" ? (
              <p className="text-danger">{errorMessage.error_message}</p>
            ) : null}

            {errorMessage?.error_detailed != null &&
            errorMessage.error_detailed != "" ? (
              <p className="text-danger">{errorMessage.error_detailed}</p>
            ) : null}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonStyled
          text={"Cancel"}
          onClickFunction={() => closeModal("update")}
          disabled={loading}
        />
        <ButtonStyled
          disabled={
            !form.first_name || !form.last_name || !isValidEmail || (form.override_advanced_commissions && !form.override_advanced_commissions_months)
          }
          text={form.pk ? "Edit" : "Create"}
          onClickFunction={() => update()}
          loading={loading}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAdd;
